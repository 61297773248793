import insightsClient from 'search-insights'
import { hashString, uuid4 } from '~/utils/cryptoHelpers'

declare global {
  interface Window {
    dataLayer: any
  }
}

export const useAlgoliaSearchInsights = () => {
  const ALGOLIA_USER_TOKEN_KEY = 'konfetti-algolia-tracking-user-token'
  const token = ref<string | undefined>(undefined)
  const hasBeenInitialized = ref(false)

  /**
   * Variables
   * */
  const $config = useRuntimeConfig()

  /**
   * Methods
   * */
  const fnGetUserToken = () => {
    let algoliaToken = null
    if (!process.server) {
      return
    }

    insightsClient('getUserToken', null, (err, userToken) => {
      if (err) {
        console.error(err)
        return
      }

      algoliaToken = userToken
    })

    return algoliaToken
  }

  const fnSetUserToken = () => {
    if (process.server) {
      return
    }

    token.value = window?.localStorage?.getItem(
      ALGOLIA_USER_TOKEN_KEY
    ) as string

    if (!token.value || token.value === 'undefined') {
      token.value = fnGetUserToken() || uuid4()
    }

    const storageUser = window?.localStorage?.getItem('user')
    if (storageUser) {
      const user = JSON.parse(storageUser)
      token.value = user?.data?.email
        ? hashString(user.data.email)
        : token.value
    }

    if (window?.localStorage) {
      window.localStorage.setItem(ALGOLIA_USER_TOKEN_KEY, token.value)
    }

    insightsClient('setUserToken', token.value)
  }

  const fnInit = () => {
    if ($config.public.appEnv !== 'production') return

    if (!process.server) {
      insightsClient('init', {
        appId: $config.public.algoliaAppId,
        apiKey: $config.public.algoliaApiClientKey,
      })

      hasBeenInitialized.value = true

      insightsClient(
        'onUserTokenChange',
        (userToken) => {
          if (!window?.dataLayer) {
            return
          }

          window.dataLayer.push({
            algoliaUserToken: userToken,
          })
        },
        { immediate: true }
      )
    }
    fnSetUserToken()
  }

  const mockedInsightsClient = (method: string, token: string) => {
    console.log('Mocked Algolia Insights Client triggered.', method, token)
  }

  const wrappedClient = (...args) => {
    if (!hasBeenInitialized.value) fnInit()
    return insightsClient(...args)
  }

  const client =
    $config.public.appEnv === 'production'
      ? wrappedClient
      : mockedInsightsClient

  return {
    client,

    token: computed(() => token.value),

    /* Methods */
    fnInit,
    fnSetUserToken,
  }
}
